import request from '@/utils/request';
import axios from 'axios';
const SNAPCHAT = process.env.VUE_APP_SNAPCHAT_AUTOGOODS;
// 编辑广告组详情查询
export function adsetForUpdate(data) {
  return request({
    baseURL: SNAPCHAT,
    url: '/snap/ads/adsetForUpdate',
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 编辑广告组
export function updateAdset(data) {
  return request({
    baseURL: SNAPCHAT,
    url: '/snap/ads/updateAdset',
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 编辑广告详情查询
export function adForUpdate(data) {
  return request({
    baseURL: SNAPCHAT,
    url: '/snap/ads/adForUpdate',
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 编辑广告
export function updateAd(data) {
  return request({
    baseURL: SNAPCHAT,
    url: '/snap/ads/updateAd',
    method: 'post',
    data,
    timeout: 90000,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 复制 下一步编辑
export function snapAdsEcFetchApi(data) {
  return request({
    baseURL: SNAPCHAT,
    url: '/snap/ads/ecFetch',
    timeout: 90000,
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
export function copyToCreate(data) {
  return request({
    baseURL: SNAPCHAT,
    url: '/snap/ads/copyToCreate',
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}


